import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-descriptions", {
    staticClass: "config-form",
    attrs: {
      column: 1,
      border: "",
      "label-class-name": "config-label",
      "content-class-name": "config-content"
    }
  }, [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-s-operation"
  }), _vm._v(" " + _vm._s(_vm.$t("public-dns.conf.label-config-name")) + " ")]), _c("div", {
    staticClass: "config-name",
    staticStyle: {
      width: "30%"
    }
  }, [_c("el-form", {
    ref: "configForm",
    attrs: {
      inline: true,
      model: _vm.configForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.configForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.configForm, "name", $$v);
      },
      expression: "configForm.name"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.updateConf();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.update")))])], 1)], 1)], 1)], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-mobile-phone"
  }), _vm._v(" " + _vm._s(_vm.$t("public-dns.conf.label-pure")) + " ")]), _c("el-switch", {
    on: {
      change: function change($event) {
        return _vm.changePure();
      }
    },
    model: {
      value: _vm.pure,
      callback: function callback($$v) {
        _vm.pure = $$v;
      },
      expression: "pure"
    }
  })], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-connection"
  }), _vm._v(" " + _vm._s(_vm.$t("public-dns.conf.label-dns-server")) + " ")]), _c("ul", {
    staticClass: "config-dns-server"
  }, _vm._l(_vm.dnsServers, function (server, idx) {
    return _c("li", {
      key: idx
    }, [_c("div", {
      staticClass: "dns-server-ip"
    }, [_vm._v(_vm._s(server))]), _c("el-tooltip", {
      attrs: {
        content: _vm.$t("public-dns.conf.copy-tip"),
        placement: "top",
        effect: "light"
      }
    }, [_c("i", {
      staticClass: "el-icon-copy-document dns-server-copy"
    })])], 1);
  }), 0)], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-location-outline"
  }), _vm._v(" " + _vm._s(_vm.$t("public-dns.conf.label-curr-ip")) + " ")]), _c("div", {
    staticClass: "config-curr-ip"
  }, [_c("div", {
    staticClass: "clinet-ip"
  }, [_vm._v(_vm._s(_vm.currIP))]), _c("i", {
    on: {
      click: function click($event) {
        return _vm.bindIP();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("public-dns.conf.bind-button")))])])], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-location"
  }), _vm._v(" " + _vm._s(_vm.$t("public-dns.conf.label-bind-ip")) + " ")]), _c("div", {
    staticClass: "config-bind-ip"
  }, [_vm.boundIP ? _c("div", [_vm._v(" " + _vm._s(_vm.boundIP) + " "), _c("i", {
    staticClass: "el-icon-success bind-ip-success"
  })]) : _c("div", [_vm._v(" " + _vm._s(_vm.$t("public-dns.conf.bind-state-unbind")) + " "), _c("i", {
    staticClass: "el-icon-error bind-ip-error"
  })])])], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };